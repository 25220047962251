import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Support from "../components/forms/Support"
import Layout from "../components/layout/Layout"
import { checkListItem, Tile } from "../components/utils/Model"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        caption: string
        tiles: Array<{
          title: string
          description: string
          icon?: string
        }>
        checkList: Array<{
          text: string
        }>
        whatsIncluded: Array<{
          title: string
          list: Array<{
            text: string
          }>
        }>
        covers: Array<{
          column: Array<{
            text: string
          }>
        }>
        asterisk: string
        apex: string
        form: {
          title: string
          subtitle: string
          termsCheckbox: string
          termsCheckboxUrl: string
          termsCheckboxLabel: string
        }
      }
    }
  }
}

const TileItem = ({ icon, title, description }: Tile): JSX.Element => (
  <div className="column is-one-third pr-6">
    {icon && (
      <div className="img has-text-centered-mobile">
        <img
          className="img-light"
          width="90px"
          src={`/img/icons/${icon}.svg`}
          alt={`${title}`}
        />
        <img
          className="img-dark"
          width="90px"
          src={`/img/icons/${icon}-dark.svg`}
          alt={`${title}`}
        />
      </div>
    )}
    <h2
      className={`is-size-4 has-text-weight-bold my-3 has-text-centered-mobile ${
        icon ? "has-icon" : "pb-4"
      }`}
    >
      {title}
    </h2>
    <div className="has-text-centered-mobile">{description}</div>
  </div>
)

// markup
const SupportTemplate = ({ data: { page } }: Props) => (
  <Layout
    seo={{
      title: page.frontmatter.title,
      description: page.frontmatter.description,
      keywords: page.frontmatter.keywords,
    }}
    className="support"
  >
    <div className="extra-0">
      <img className="img-light" src={`/img/extra/support-1.svg`} alt="" />
      <img className="img-dark" src={`/img/extra/support-1-dark.svg`} alt="" />
    </div>
    <div className="container py-6">
      <HTMLContent
        content={page.html}
        className={"markdown hero"}
      ></HTMLContent>
      <div className="info">
        <div className="columns">
          {page.frontmatter.tiles.map(
            (tile: Tile, index: number): JSX.Element => (
              <TileItem key={`tile-${index}`} {...tile} />
            )
          )}
        </div>
        <div className="columns subscription pb-6">
          <img width="32px" src={`/img/icons/checked-icon.svg`} alt="checked" />
          <div className="column is-three-thirds">
            <h2>{page.frontmatter.checkList[0].text}</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="has-background-light py-6 has-big-border-bottom">
      <div className="container">
        <div className="pb-5 mt-6 is-size-4 columns">
          <h2 className="column is-one-third has-text-weight-bold title">
            {page.frontmatter.whatsIncluded[0].title}
          </h2>
          <div className="column is-one-third">
            <div className="support-wrapper">
              <h3 className="has-text-weight-bold has-big-border-dark-top title-secondary">
                {page.frontmatter.whatsIncluded[1].title}
              </h3>
              <div className="list">
                {page.frontmatter.whatsIncluded[1].list.map(
                  (value: { text: string }, index: number): JSX.Element => (
                    <div key={`item-${index}`} className="item">
                      {value.text}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="support-wrapper">
              <h3 className="has-text-weight-bold has-big-border-dark-top title-secondary">
                {page.frontmatter.whatsIncluded[2].title}
              </h3>
              <div className="list">
                {page.frontmatter.whatsIncluded[2].list.map(
                  (value: { text: string }, index: number): JSX.Element => (
                    <div key={`item-${index}`} className="item">
                      {value.text}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5 mt-6 has-big-border-dark-top pt-5 is-size-4 covers">
          <h3 className="covers-title has-text-weight-bold">
            Technical support covers
          </h3>
          <div className="columns">
            {page.frontmatter.covers.map(
              (list: any, index: number): JSX.Element => (
                <div className="column" key={`column-${index}`}>
                  {list.column.map(
                    (item: any): JSX.Element => (
                      <div className="item">{item.text}</div>
                    )
                  )}
                </div>
              )
            )}
          </div>
          <p className="caption">{page.frontmatter.caption}</p>
        </div>
      </div>
    </div>
    <div className="letstalk py-6">
      <div className="container my-6">
        <div className="columns">
          <div className="column is-7">
            <h2 className="is-size-2 has-text-white has-text-weight-bold">
              {page.frontmatter.form.title}
            </h2>
            <p className="is-size-5 has-text-white pt-6">
              {page.frontmatter.form.subtitle}
            </p>
          </div>
          <div className="column is-5">
            <Support />
          </div>
        </div>
      </div>
    </div>
    <DefaultBottom />
  </Layout>
)

export default SupportTemplate

// graphQL queries
export const pageQuery = graphql`
  query SupportTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        caption
        tiles {
          icon
          title
          description
        }
        checkList {
          text
        }
        whatsIncluded {
          title
          list {
            text
          }
        }
        covers {
          column {
            text
          }
        }
        asterisk
        apex
        form {
          title
          subtitle
          termsCheckbox
          termsCheckboxUrl
          termsCheckboxLabel
        }
      }
    }
  }
`
