import * as React from "react"

const MKFORM = {
  scriptUrl: "//app-sj05.marketo.com/js/forms2/js/forms2.min.js",
  scriptId: "mktoForms",
  mkUrl: "//app-sj05.marketo.com",
  mkId: "625-IUJ-009",
  formId: "7985",
}

const Support = () => {
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    if (!document.getElementById(MKFORM.scriptId)) {
      loadScript()
    } else {
      setIsLoaded(true)
    }
  }, [])

  React.useEffect(() => {
    isLoaded && window.MktoForms2 &&
      window.MktoForms2.loadForm(
        MKFORM.mkUrl,
        MKFORM.mkId,
        MKFORM.formId,
        (form: any) => {
          form.setValues({
            Product_Selection__c: "Pivotal Spring Runtime",
            Most_Recent_Lead_Source_Description__c:
              "Global_Website_Product Contact",
            Original_Lead_Source_Description__c:
              "Global_Website_Product Contact",
          })
        }
      )
  }, [isLoaded, MKFORM])

  const loadScript = () => {
    var s = document.createElement("script")
    s.id = MKFORM.scriptId
    s.type = "text/javascript"
    s.async = true
    s.src = MKFORM.scriptUrl
    s.onreadystatechange = function () {
      if (this.readyState === "complete" || this.readyState === "loaded") {
        setIsLoaded(true)
      }
    }
    s.onload = () => setIsLoaded(true)
    document.getElementsByTagName("head")[0].appendChild(s)
  }

  return (
    <div id={`support`}>
      <form id={`mktoForm_${MKFORM.formId}`}></form>
    </div>
  )
}

export default Support
